import $ from 'jquery';
import StorageKeys from './utils/storage/StorageKeys';

const AbdetCookie = {
  initialize() {
    const { cltAnalyticsEnabled, analyticsBlockDetectionEnabled } = window.AppSettings;

    const hasCookieConsent = window.AppState.hasAnalyticsConsent;

    if (
      cltAnalyticsEnabled &&
      analyticsBlockDetectionEnabled &&
      $.cookie(StorageKeys.COOKIE_ABDET) === undefined &&
      hasCookieConsent
    ) {
      AbdetCookie.checkAnalyticsEndpoint();
    }
  },

  checkAnalyticsEndpoint() {
    const url = 'https://clt.g-star.com';

    fetch(url, { method: 'HEAD', mode: 'no-cors' })
      .then(() => {
        $.cookie(StorageKeys.COOKIE_ABDET, false, { path: '/' });
      })
      .catch(() => {
        $.cookie(StorageKeys.COOKIE_ABDET, true, { path: '/' });
      });
  },
};

export default AbdetCookie;
