/* global AppSettings, AppState */
/**
 * To keep browsers from filtering out any URL
 * containing "google" in combination of "analytics",
 * I've decided to name this file "ContextData"
 * as it concerns data from the browsing context.
 *
 * @author Vincent Bruijn <vincent-bruijn@g-star.com>
 */
// eslint-disable-next-line no-unused-vars
import $ from 'jquery';
import createLogger from 'components/logger/Logger';
import AnalyticsEventTypes from 'components/analytics/AnalyticsEventTypes';
import EventTypes from 'components/EventTypes';
import Storage from 'components/utils/storage/Storage';
import StorageKeys from 'components/utils/storage/StorageKeys';
import Ecommerce from './Ecommerce';
import Interactions from './Interactions';
import Links from './Links';
import Exponea from './Exponea';
import Contentsquare from './Contentsquare';
import Qubit from './Qubit';
import BazaarVoice from './BazaarVoice';
import Algolia from './Algolia';
import onWindowLoaded from '../utils/onWindowLoaded';

const Logger = createLogger('ContextData');

class ContextData {
  constructor() {
    if (!AppState.analyticsEnabled) {
      return;
    }
    Logger.info('Initialized');
    new Links();
    new Qubit();
    new Contentsquare();
    new BazaarVoice();
    new Algolia();
    this.state = { productData: {} };
    this.bindEvents();
  }

  bindEvents() {
    document.jq
      .one(AnalyticsEventTypes.QUBIT_EMIT_PAGELOAD_BASKET_EVENTS, (event, eventData) =>
        Qubit.emitBasketEvents(eventData)
      )
      .on(AnalyticsEventTypes.CART_UPDATED, (event, eventData) => this.pushCartData(eventData))
      .on(AnalyticsEventTypes.CONTACT_FORM_SUBMIT, (event, eventData) =>
        this.pushContactFormData(eventData)
      )
      .on(AnalyticsEventTypes.COOKIE_CLICK, (event, eventData) => this.pushCookieData(eventData))
      .on(AnalyticsEventTypes.EXTERNAL_LINK_CLICK, (event, eventData) =>
        this.pushExternalLinkData(eventData)
      )
      .on(AnalyticsEventTypes.FAQ_ITEM_OPEN, (event, eventData) => this.pushFaqData(eventData))
      .on(AnalyticsEventTypes.FILTER_APPLIED, (event, eventData) => {
        this.pushAppliedFilterData(eventData);

        Algolia.clickedFilters(eventData);
      })
      .on(AnalyticsEventTypes.INTERNAL_SEARCH, (event, eventData) =>
        this.pushInternalSearchData(eventData)
      )
      .on(AnalyticsEventTypes.PDP_THUMBNAIL_CLICK, (event, eventData) =>
        this.pushThumbnailData(eventData)
      )
      .on(AnalyticsEventTypes.PRODUCT_CLICK, (event, eventData) => this.pushProductClick(eventData))
      .on(AnalyticsEventTypes.PRODUCT_DETAIL_IMPRESSION, (event, eventData) =>
        this.pushProductDetailImpression(eventData)
      )
      .on(AnalyticsEventTypes.PRODUCT_IMPRESSION, (event, eventData) =>
        this.pushProductImpression(eventData)
      )
      .on(AnalyticsEventTypes.SIZE_GUIDE_OPEN, (event, eventData) =>
        this.pushSizeGuideData(eventData)
      )
      .on(AnalyticsEventTypes.SORTING_OPTION_CLICK, (event, eventData) =>
        this.pushSortingOptionData(eventData)
      )
      .on(AnalyticsEventTypes.STOCK_LOCATOR, (event, eventData) => this.pushStockLocator(eventData))
      .on(AnalyticsEventTypes.STORE_FINDER_SEARCH, (event, eventData) =>
        this.pushStoreFinderData(eventData)
      )
      .on(AnalyticsEventTypes.USER_INFO, (event, eventData) => {
        this.pushUserInfo(eventData);
        this.handleUserLoggedInStatus(eventData);
      })
      .on(AnalyticsEventTypes.VIEW_AS_CLICK, (event, eventData) => this.pushViewAsData(eventData))
      .on(AnalyticsEventTypes.WHERE_IS_IT_MADE_OPEN, (event, eventData) =>
        this.pushWhereIsItMadeData(eventData)
      )
      .on('click', '[data-analytics-product-click]', event => this.processProductClick(event))
      .on(AnalyticsEventTypes.CATEGORY_IMPRESSION, (event, eventData) => {
        this.pushCategoryImpression(eventData);
        Algolia.viewedFilters();
      })
      .on(AnalyticsEventTypes.NEWSLETTER_SUBSCRIBE, (event, eventData) =>
        this.pushNewsletterSubscribeData(eventData)
      )
      .on(AnalyticsEventTypes.STOCK_NOTIFICATION_OPEN, (event, eventData) =>
        this.pushStockNotificationOpenData(eventData)
      )
      .on(AnalyticsEventTypes.STOCK_NOTIFICATION_SUBMIT, (event, eventData) =>
        this.pushStockNotificationSubmitData(eventData)
      )
      .on(AnalyticsEventTypes.COMING_SOON_NOTIFICATION_OPEN, (event, eventData) =>
        this.pushComingSoonNotificationOpenData(eventData)
      )
      .on(AnalyticsEventTypes.COMING_SOON_NOTIFICATION_SUBMIT, (event, eventData) =>
        this.pushComingSoonNotificationSubmitData(eventData)
      )
      .on(EventTypes.PRODUCT_FORM_SIZE_SELECTED, (event, eventData) =>
        this.emitQubitProductPageEvent(eventData)
      )
      .on(EventTypes.WISHLIST_UPDATE_ITEMS, (event, eventData) =>
        this.pushWishlistUpdateData(eventData)
      )
      .on(AnalyticsEventTypes.WISHLIST_MODAL_OPEN, (event, eventData) =>
        this.pushWishlistModalOpenData(eventData)
      )
      .on(AnalyticsEventTypes.RETURN_FEE_PDP_MESSAGE_VIEW_EVENT, (event, eventData) =>
        this.pushReturnFeePdpMessageViewEvent(eventData)
      );
  }

  /**
   * Collect and prepare product data and then emit product page event to qubit
   * @param {object}
   * @returns {object}
   */
  emitQubitProductPageEvent({ sku, numberOfSelectors, name, value }) {
    const activeDialog = document.querySelector('.has-activeDialog');

    if (activeDialog || AppSettings.pageType !== 'PRODUCT') return;

    this.state.productData[name] = value === '' ? 'undefined' : value;
    this.state.productData.size =
      numberOfSelectors === 2
        ? this.state.productData.gridvalue1 + this.state.productData.gridvalue2
        : this.state.productData.gridvalue1;

    const isAllSizesSelected = this.state.productData.size.indexOf('undefined') === -1;

    if (!isAllSizesSelected) return;

    const product = document.querySelector('article[data-analytics-detail]');
    let productData = product?.dataset.productData;

    if (!productData) return;

    try {
      productData = JSON.parse(productData);
    } catch (e) {
      Logger.warn(`Unable to parse data-analytics-detail attribute: ${e.message}`);
    }

    productData.size = this.state.productData.size;
    productData.ecomm_prodid = `${sku}-${productData.size}`;

    Qubit.emitProductPageEvents(productData);
  }

  /**
   * Process a click on a product tile
   * @param {jQuery.Event} event jQuery Event object
   */
  processProductClick(event) {
    const target = event.currentTarget;
    let productData = (target && target.dataset.productData) || '';
    const productTileContainer = target.closest('[data-tile-type]');
    const productTileType = productTileContainer && productTileContainer.dataset.tileType;

    if (!productData || !productTileType) {
      return;
    }

    // check if link is clicked with ctrl or cmd button pressed
    // i.e. open in new tab
    const isAlternativeClick = isAlternativeClickFn(event);

    if (!isAlternativeClick) {
      event.preventDefault();
    }

    try {
      productData = JSON.parse(productData);
      if (typeof productData === 'string') {
        productData = JSON.parse(productData);
      }

      productData.viewType = target.dataset.viewtype;
      productData.objectID = target.dataset.algoliaObjectid;
    } catch (e) {
      Logger.warn(`Unable to JSON.parse string of productData: ${e.message}`);
      return;
    }

    const followLinkFn = assignDocumentLocation.bind(this, target.href, event);
    // prevent accidental request initialization by gtm
    let isCallbackCalled = false;

    const eventCallback = () => {
      if (isCallbackCalled) {
        return;
      }
      isCallbackCalled = true;
      clearTimeout(timeoutId);
      followLinkFn();
    };

    Storage.setItem(
      `${StorageKeys.PRODUCT_TILE_TYPE}-${productData.materialNumber}`,
      productTileType
    );

    this.pushProductClick({
      productTileType,
      click: {
        products: [productData],
      },
      eventCallback,
    });

    const timeoutId = setTimeout(eventCallback, 750);
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-111
   * @param {Object} eventData
   */
  pushAppliedFilterData(eventData) {
    const appliedFilterData = Interactions.getAppliedFilterData(eventData);
    window.gsDataLayer.push(appliedFilterData);
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-107
   * @param {*} eventData
   */
  pushContactFormData(eventData) {
    const contactFormData = Interactions.getContactFormData(eventData);
    window.gsDataLayer.push(contactFormData);
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-97
   * @param {Object} eventData
   */
  pushCookieData(eventData) {
    const cookieData = Interactions.getCookieData(eventData);
    window.gsDataLayer.push(cookieData);

    Algolia.initialize()
    Algolia.bindEvents();
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-108
   * @param {Object} eventData
   */
  pushFaqData(eventData) {
    const faqData = Interactions.getFaqData(eventData);
    window.gsDataLayer.push(faqData);
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-101
   * @param {Object} eventData
   */
  pushStockLocator(eventData) {
    const stockLocatorData = Interactions.getStockLocatorData(eventData);
    window.gsDataLayer.push(stockLocatorData);
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-86
   * @param {Object} eventData
   */
  pushUserInfo(eventData) {
    const userInfo = { ...eventData };
    window.gsDataLayer.push(userInfo);
  }

  /**
   * https://g-star.atlassian.net/browse/TK-2665
   * @param {Object} eventData
   * @returns {undefined}
   */
  handleUserLoggedInStatus(eventData) {
    const currentLoggedInStatus = eventData?.user?.loggedInStatus;
    let previousLoggedInStatus;

    try {
      previousLoggedInStatus = JSON.parse(
        Storage.getItem(StorageKeys.USER_LOGGED_IN_STATUS) || 'false'
      );
    } catch (e) {
      previousLoggedInStatus = false;
    }

    if (currentLoggedInStatus === previousLoggedInStatus) return;

    if (currentLoggedInStatus && currentLoggedInStatus !== previousLoggedInStatus) {
      const userLoggedInData = Interactions.getUserLoggedInData(eventData);
      window.gsDataLayer.push(userLoggedInData);

      return Storage.setItem(StorageKeys.USER_LOGGED_IN_STATUS, true);
    }

    return Storage.removeItem(StorageKeys.USER_LOGGED_IN_STATUS);
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-110
   * @param {Object} eventData
   */
  pushInternalSearchData(eventData) {
    const internalSearchData = Interactions.getInternalSearchData(eventData);
    window.gsDataLayer.push(internalSearchData);
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-87
   * @param {Object} eventData
   */
  pushProductImpression(eventData) {
    const productImpressionData = Ecommerce.getProductImpressionData(eventData);
    const pushCallback = () => {
      productImpressionData && window.gsDataLayer.push(productImpressionData);
    };

    onWindowLoaded(pushCallback);
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-88
   * @param {Object} eventData
   */
  pushProductClick(eventData) {
    const productClickData = Ecommerce.getProductClickData(eventData);
    window.gsDataLayer.push(productClickData);

    Algolia.handleProductClick(eventData);
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-89
   * @param {Object} eventData
   */
  pushProductDetailImpression(eventData) {
    const productDetailImpressionData = Ecommerce.getProductDetailImpressionData(eventData);
    const exponeaData = Exponea.getProductDetailImpressionData(eventData);

    const pushCallback = () => {
      Qubit.emitProductDetailViewEvent(eventData);
      [exponeaData, productDetailImpressionData].map(data => window.gsDataLayer.push(data));

      // Algolia.viewedObjectIDs(eventData);
    };

    onWindowLoaded(pushCallback);
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-90
   * https://g-star.atlassian.net/browse/DAN-91
   * @param {Object} eventData
   */
  pushCartData(eventData) {
    const cartData = Ecommerce.getCartData(eventData);
    const cartDataExponea = Exponea.getCartUpdateData(eventData);
    [cartDataExponea, cartData].map(data => data && window.gsDataLayer.push(data));
    Qubit.ecBasketItemActionEvent(cartData);

    Algolia.convertedObjectIDsAfterSearch();
  }

  pushCategoryImpression(eventData) {
    const categoryData = Exponea.getViewCategoryEventData(eventData);
    window.gsDataLayer.push(categoryData);
    Qubit.emitViewCategoryEvent(eventData);
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-104
   * @param {Object} eventData
   */
  pushSortingOptionData(eventData) {
    const sortingOptionData = Interactions.getSortingOptionData(eventData);
    window.gsDataLayer.push(sortingOptionData);
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-100
   * @param {Object} eventData
   */
  pushStoreFinderData(eventData) {
    const storeFinderData = Interactions.getStoreFinderData(eventData);
    window.gsDataLayer.push(storeFinderData);
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-103
   * @param {*} eventData
   */
  pushExternalLinkData(eventData) {
    const externalLinkData = Interactions.getExternalLinkData(eventData);
    window.gsDataLayer.push(externalLinkData);
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-109
   * @param {Object} eventData
   */
  pushSizeGuideData(eventData) {
    const sizeGuideData = Interactions.getSizeGuideData(eventData);
    window.gsDataLayer.push(sizeGuideData);
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-99
   * @param {Object} eventData
   */
  pushThumbnailData(eventData) {
    const thumbnailData = Interactions.getThumbnailData(eventData);
    window.gsDataLayer.push(thumbnailData);
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-105
   * @param {Object} eventData
   */
  pushViewAsData(eventData) {
    const viewAsData = Interactions.getViewAsData(eventData);
    window.gsDataLayer.push(viewAsData);
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-98
   * @param {Object} eventData
   */
  pushWhereIsItMadeData(eventData) {
    const whereIsItMadeData = Interactions.getWhereIsItMadeData(eventData);
    window.gsDataLayer.push(whereIsItMadeData);
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-785
   * @param {Object} eventData
   */
  pushNewsletterSubscribeData(eventData) {
    const newsletterData = Interactions.getNewsletterData(eventData);
    window.gsDataLayer.push(newsletterData);
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-770
   * @param {Object} eventData
   */
  pushStockNotificationOpenData(eventData) {
    const stockNotificationData = Interactions.getStockNotificationOpenData(eventData);
    window.gsDataLayer.push(stockNotificationData);
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-770
   * @param {Object} eventData
   */
  pushStockNotificationSubmitData(eventData) {
    const stockNotificationData = Interactions.getStockNotificationSubmitData(eventData);
    window.gsDataLayer.push(stockNotificationData);
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-771
   * @param {Object} eventData
   */
  pushComingSoonNotificationOpenData(eventData) {
    const comingSoonData = Interactions.getComingSoonNotificationOpenData(eventData);
    window.gsDataLayer.push(comingSoonData);
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-771
   * @param {Object} eventData
   */
  pushComingSoonNotificationSubmitData(eventData) {
    const comingSoonData = Interactions.getComingSoonNotificationSubmitData(eventData);
    window.gsDataLayer.push(comingSoonData);
  }

  /**
   * https://g-star.atlassian.net/browse/TK-414
   * @param {Object} eventData
   */
  pushWishlistUpdateData(eventData) {
    const wishlistUpdateData = Interactions.getWishlistUpdateData(eventData);
    const exponeaData = Exponea.getWishlistUpdateData(eventData);

    [wishlistUpdateData, exponeaData].map(data => data && window.gsDataLayer.push(data));
  }

  /**
   * https://g-star.atlassian.net/browse/TK-757
   * @param {Object} eventData
   */
  pushWishlistModalOpenData(eventData) {
    const wishlistModalOpenData = Interactions.getWishlistModalOpenData(eventData);
    window.gsDataLayer.push(wishlistModalOpenData);
  }

  /**
   * https://g-star.atlassian.net/browse/TK-1965
   * @param {Object} eventData
   */
  pushReturnFeePdpMessageViewEvent(eventData) {
    const returnFeePdpMessageViewEventData =
      Interactions.getReturnFeePdpMessageViewEventData(eventData);
    returnFeePdpMessageViewEventData && window.gsDataLayer.push(returnFeePdpMessageViewEventData);
  }
}

/**
 * Has event a related element which opens a link in a new target
 * @param  {jQuery.Event} event
 * @return {Boolean}
 */
function hasLinkWithTarget(event) {
  if (event) {
    let element = event.target.tagName === 'A' ? event.target : event.currentTarget || event.target;
    if (!(element instanceof Element)) {
      element = event.target;
    }

    if (element && element.tagName && element.tagName !== 'A') {
      element = element.closest('a');
    }

    if (element) {
      const { target } = element;
      return target && target !== '_self';
    }
  }

  return false;
}

/**
 * Check if click event is executed while CTRL key is down or if it's a click with right or middle button of mouse
 * @param  {jQuery.Event}  event
 * @return {Boolean}       Is alternative click?
 */
function isAlternativeClickFn(event) {
  return (
    event.ctrlKey ||
    event.which === 2 ||
    event.which === 3 ||
    event.metaKey ||
    hasLinkWithTarget(event)
  );
}

function assignDocumentLocation(url, event) {
  if (!isAlternativeClickFn(event) && (!event.data || !event.data.preventDefault)) {
    if (url) {
      Logger.debug('assignDocumentLocation: Proceed to link', url);
      document.location.assign(url);
    } else {
      Logger.debug('assignDocumentLocation: no url found');
    }
  } else {
    Logger.debug('assignDocumentLocation: do nothing');
  }
}

export default ContextData;
